import React from "react";
import styled from "styled-components";
import { AppStore, PdfQr, Playstore, PostCinemark, Qr } from "../../../images";
import QRCode from "react-qr-code";
import { mediaQuery } from "../../../styles/constants/mediaQuery";

export const SectionSocial = () => (
  <Container>
    <section className="containerQr">
      <div className="containerQr__mobileApp">
        <img src={Playstore} alt="imagen playstore"/>
        <img src={AppStore} alt="imagen appStore"/>
      </div>
      <div className="item-image">
        <a
            href="https://play.google.com/store/apps/details?id=com.servitec.cobiene"
            target="_blank"
            className="wrappe-qr wrappe-qr-list"
        >
          <QRCode value="https://play.google.com/store/apps/details?id=com.servitec.cobiene"/>
        </a>
        <h3>App Bienestar EP</h3>
      </div>
      <div className="item-image">
        <a
            href="https://denuncias.servicios.gob.pe/"
            target="_blank"
            className="wrappe-qr wrappe-qr-list"
        >
          <QRCode value="https://denuncias.servicios.gob.pe/"/>
        </a>
        <h3>Denuncias Ciudadanas</h3>
      </div>
      <div className="item-image">
        <a
            href="https://cobiene.mil.pe/pdf-view/solicitudConvenios"
            className="wrappe-qr wrappe-qr-list"
        >
          <QRCode value="https://cobiene.mil.pe/pdf-view/solicitudConvenios"/>
        </a>
        <h3>Solicitud de convenios</h3>
      </div>
      <div className="item-image">
        <a
            href="https://cobiene.mil.pe/pdf-view/multiservicios"
            className="wrappe-qr wrappe-qr-list"
        >
          <QRCode value="https://cobiene.mil.pe/pdf-view/multiservicios"/>
        </a>
        <h3>Convenio Multiservicios</h3>
      </div>
      <div className="item-image">
        <a
            href="https://cobiene.mil.pe/pdf-view/estudios"
            className="wrappe-qr wrappe-qr-list"
        >
          <QRCode value="https://cobiene.mil.pe/pdf-view/estudios"/>
        </a>
        <h3>Convenios Estudios</h3>
      </div>
    </section>
    <section className="container-posts">
      <div className="container-posts__facebook-iframe">
        <h2>Siguenos en Facebook:</h2>
        <div className="section-iframe">
          <iframe
              title="facebook"
              src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fweb.facebook.com%2Fprofile.php%3Fid%3D61555409192993&tabs=timeline&width=300&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
            height="450"
            width="300"
            scrolling="no"
            frameBorder="0"
            allowFullScreen="true"
            loading="lazy"
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
            style={{ margin: "auto" }}
          ></iframe>
        </div>
      </div>
    </section>
  </Container>
);

const Container = styled.div`
  background: rgba(11, 11, 11);
  padding: 2em;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 2em;

  .containerQr {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(180px, 180px));
    grid-auto-rows: 220px;
    justify-content: center;
    gap: 1em;

    img {
      width: 100%;
    }

    .wrappe-qr {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
    }

    .wrappe-qr-list {
      height: 180px;
      padding: 0 0.8em;
    }

    h3 {
      font-size: 0.9em;
      text-align: center;
      padding-top: 0.5rem;
    }

    &__mobileApp {
      display: flex;
      flex-direction: column;
      gap: 1em;
      flex: 1 1 0;
      img {
        width: 100%;
      }
    }
  }

  .container-posts {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 4em;

    &__facebook-iframe {
      display: flex;
      flex-direction: column;
      align-items: center;

      .section-iframe {
        width: 305px;
        border: 2px dashed #fff;
        display: flex;
        justify-content: center;
        margin-top: 1em;
      }

      h2 {
        font-size: 1.5em;
        font-weight: 600;
      }
    }
  }
`;
